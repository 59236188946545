/** @jsx jsx */

import React, { useState, useRef, useEffect } from 'react'
import { css, jsx } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'

type ShopModuleProps = {
  item: {
    titel: string
    kanBesteldWorden: boolean
    jaarGemaakt: number
    shopOpties: ShopOptie[]
    shopAfbeelding: any
    shopTitel: string
    shopText: string
    afbeelding: any
  }
}

type ShopOptie = {
  prijs: string
  text: string
}

export default function ShopModule({ item }: ShopModuleProps) {
  const emailRef = useRef('')
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [emailInput, setEmailInput] = useState('')
  const opmerkingenInputRef = useRef('')
  const [opmerkingenInput, setOpmerkingenInput] = useState('')
  const optionsRef = useRef<{ [x: string]: boolean }>({})
  const [options, setOptions] = useState<{ [x: string]: boolean }>({})

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    emailRef.current = e.target.value
    setEmailInput(e.target.value)
  }
  function handleOpmerkingenChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    opmerkingenInputRef.current = e.target.value
    setOpmerkingenInput(e.target.value)
  }

  function handleOptionsChange(keyName: string) {
    optionsRef.current[keyName] = !optionsRef.current[keyName]
    setOptions({ ...optionsRef.current })
  }

  useEffect(() => {
    item.shopOpties.forEach((optie) => {
      optionsRef.current[optie.text] = false
    })
    setOptions({ ...optionsRef.current })
    return () => {}
  }, [])

  async function handleOrder() {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRef.current && regex.test(emailRef.current)) {
      if (
        Object.keys(optionsRef.current).some(
          (option) => optionsRef.current[option]
        )
      ) {
        let optionsString = ''
        item.shopOpties.forEach((option) => {
          if (optionsRef.current[option.text]) {
            optionsString += `${option.text} -- ${option.prijs}, `
          }
        })
        const orderImgUrl = item.afbeelding.gatsbyImageData.images.fallback.src
        const url = `/.netlify/functions/sendOrderMail?titel=${item.titel}&email=${emailRef.current}&sizePrice=${optionsString}&opmerkingen=${opmerkingenInputRef.current}&imgSrc=${orderImgUrl}`

        try {
          const response = await fetch(url)
          if (response.status === 200) {
            window.alert(
              'Bedankt voor je bestelling, je krijgt een bevestiging per email en ik neem snel contact met je op!'
            )
          } else {
            window.alert(
              'Sorry, er is iets misgegaan en je bestelling kon nu niet verwerkt worden. Probeer het later opnieuw.'
            )
          }
        } catch (e) {
          console.warn(e)
          window.alert(
            'Sorry, your order could not be processed right now. Please try again later.'
          )
        }
      } else {
        window.alert('Kies eerst welk formaat je wil bestellen!')
      }
    } else {
      window.alert('Please enter a valid email adress')
      if (emailInputRef.current) emailInputRef.current.focus()
    }
  }

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <h3>{item.shopTitel}</h3>
      <p dangerouslySetInnerHTML={{ __html: item.shopText }}></p>
      <p
        css={css`
          margin-bottom: 0;
        `}
      >
        Email:
      </p>
      <input
        placeholder="Enter your email adress"
        type="email"
        value={emailInput}
        ref={emailInputRef}
        onChange={(e) => handleEmailChange(e)}
        css={css``}
      ></input>
      {item.shopOpties.map((optie, index) => {
        return (
          <div
            key={index}
            css={css`
              cursor: pointer;
              display: flex;
              width: 100%;
              margin-top: 10px;
            `}
          >
            <input
              css={css`
                display: inline;
              `}
              type="checkbox"
              checked={options[optie.text]}
              id={index.toString()}
              onChange={() => handleOptionsChange(optie.text)}
            />

            <span>{optie.text}</span>
            <span
              css={css`
                display: inline-block;
                margin-left: auto;
              `}
            >
              {optie.prijs}
            </span>
          </div>
        )
      })}
      <p
        css={css`
          margin-bottom: 0;
          margin-top: 20px;
        `}
      >
        Opmerkingen:
      </p>
      <textarea
        rows={3}
        css={css`
          display: block;
        `}
        value={opmerkingenInput}
        onChange={(e) => handleOpmerkingenChange(e)}
      />
      <button css={css``} onClick={handleOrder}>
        Bestel
      </button>
      {item.shopAfbeelding?.gatsbyImageData && (
        <GatsbyImage
          css={css`
            margin-top: 40px;
          `}
          image={item.shopAfbeelding.gatsbyImageData}
          alt="Shop preview"
        />
      )}
    </div>
  )
}
