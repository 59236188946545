/** @jsx jsx */

import React, { useState, useEffect, Fragment, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { LinkButton } from '../components/Button'
import { PortfolioImage } from '../components/Portfolioimage'
import Layout from '../components/Layout'
import { variables } from '../styles/global'
import renderStructuredText from '../util/StructuredTextToHtml'
import ShopModule from '../components/ShopModule'
import SEO from '../components/Seo'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Link } from 'gatsby'

export function PortfolioItem({ pageContext }: any) {
  const series = pageContext.series

  const [showImg, setShowImg] = useState(true)
  const [currentItem, setCurrentItem] = useState(0)
  const shopRef = useRef<HTMLDivElement>(null)
  const fadeDuration = 200

  const handlePortfolioBrowse = (index: number) => {
    setShowImg(false)
    setTimeout(() => {
      handlePortfolioItemChange(index)
      setShowImg(true)
    }, fadeDuration)
  }

  const handlePortfolioItemChange = (change: number) => {
    let i = currentItem + change
    if (i < 0) {
      i = series.kunstwerk.length - 1
    } else if (i > series.kunstwerk.length - 1) {
      i = 0
    }
    setCurrentItem(i)
  }

  function scrollToShop() {
    if (typeof window !== 'undefined' && shopRef.current)
      window.scrollTo(0, shopRef.current.getBoundingClientRect().top)
  }

  return (
    <Layout>
      <SEO title={series.titel} />

      <div className="container">
        <div
          css={css`
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            ${variables.mobile} {
              display: block;
              text-align: center;
            }
          `}
        >
          <div
            css={css`
              width: 45%;
              padding: 100px 0 0 30px;
              margin-top: 0px;
              height: 100%;
              ${variables.mobile} {
                width: 100%;
                padding: 0;
                margin-top: 0;
              }
            `}
          >
            <h2
              css={css`
                font-weight: 400;
                font-size: 1.6rem;
              `}
            >
              {series.titel}
            </h2>
            <p
              css={css`
                margin-bottom: 20px;
              `}
              dangerouslySetInnerHTML={{
                __html: renderStructuredText(series.beschrijving.value),
              }}
            ></p>
            {series.kunstwerk[currentItem].kanBesteldWorden && (
              <Fragment>
                <div
                  onClick={scrollToShop}
                  css={css`
                    cursor: pointer;
                    text-decoration: underline;
                    margin-bottom: 90px;
                    ${variables.mobile} {
                      display: block;
                      margin-bottom: 15px;
                    }
                  `}
                >
                  <Link to="/">Terug naar portfolio</Link>
                </div>
                <div
                  css={css`
                    ${variables.mobile} {
                      display: none;
                    }
                  `}
                >
                  <ShopModule item={series.kunstwerk[currentItem]} />
                </div>

                <div
                  onClick={scrollToShop}
                  css={css`
                    cursor: pointer;
                    display: none;
                    text-decoration: underline;
                    ${variables.mobile} {
                      display: block;
                    }
                  `}
                >
                  {series.kunstwerk[currentItem].shopTitel}
                </div>
              </Fragment>
            )}
          </div>
          <div
            css={css`
              width: 55%;
              position: relative;
              margin-top: 50px;
              ${variables.mobile} {
                width: 100%;
              }
            `}
          >
            <span
              css={css`
                ${variables.mobile} {
                  font-size: 1.4rem;
                  margin-bottom: 15px;
                }
                font-size: 2rem;
                display: block;
                font-weight: 600;
              `}
            >
              {series.kunstwerk[currentItem].titel}
            </span>

            {series.kunstwerk.length > 1 && (
              <div
                css={css`
                  display: inline-flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 10px;
                  font-size: 1.2rem;
                  ${variables.mobile} {
                    font-size: 1rem;
                    width: 100%;
                  }
                `}
              >
                <BsArrowLeft
                  css={css`
                    cursor: pointer;
                    width: 30px;
                    height: 25px;
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    ${variables.mobile} {
                      margin-right: 0px;
                    }
                  `}
                  onClick={() => handlePortfolioBrowse(-1)}
                />

                <span
                  css={css`
                    margin-right: 30px;
                    ${variables.mobile} {
                      margin-right: 0px;
                    }
                  `}
                >
                  {currentItem + 1} / {series.kunstwerk.length}
                </span>

                <BsArrowRight
                  css={css`
                    cursor: pointer;
                    width: 30px;
                    height: 25px;
                    margin-right: 30px;
                    display: flex;
                    align-items: center;
                    ${variables.mobile} {
                      margin-right: 0px;
                    }
                  `}
                  onClick={() => handlePortfolioBrowse(1)}
                />
              </div>
            )}
            <div
              css={css`
                transition: opacity ${fadeDuration}ms;
              `}
              className={showImg ? 'show' : 'hide'}
              onClick={() => {
                handlePortfolioBrowse(1)
              }}
              role="button"
            >
              <PortfolioImage
                image={series.kunstwerk[currentItem].afbeelding.gatsbyImageData}
              />
            </div>

            <div
              css={css`
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-size: 0.8rem;
              `}
            >
              <div>{series.kunstwerk[currentItem].materiaal}</div>
              <div>{series.kunstwerk[currentItem].jaarGemaakt}</div>
            </div>
          </div>
          {series.kunstwerk[currentItem].kanBesteldWorden && (
            <div
              ref={shopRef}
              css={css`
                display: none;
                ${variables.mobile} {
                  display: block;
                }
              `}
            >
              <ShopModule item={series.kunstwerk[currentItem]} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PortfolioItem
